import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { fromEvent } from 'rxjs';

import { withModalActions, withLocale } from 'core/hocs';
import { wrapRoute2Locale } from 'core/helpers';

import { Main } from 'components/main/main';
import { Home } from 'components/home/home';
import { HeadHelmet } from 'components/head-helmet/head-helmet';
import { PAGE_NAMES } from '../../constants';

class HomeUI extends Component {
  static propTypes = {
    match: PropTypes.shape().isRequired,
    history: PropTypes.shape().isRequired,
    locale: PropTypes.string.isRequired,
  };
  loadWindowSubscription;

  componentDidMount() {
    this.loadWindowSubscription = fromEvent(window, 'load').subscribe(this.onLoad);
  }

  componentWillUnmount() {
    this.loadWindowSubscription.unsubscribe();
  }

  onLoad = () => {
    const {
      match: { url }, history, locale
    } = this.props;

    if (url.includes('cashier')) {
      history.push(wrapRoute2Locale('/', locale));
    }
  }

  render() {
    return (
      <Main className="home-page">
        <HeadHelmet page={PAGE_NAMES.HOME} />

        <Home />
      </Main>
    );
  }
}

export default withModalActions(withLocale(HomeUI));
