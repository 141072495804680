import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withCoreComponent } from 'core/hocs';
import { TournamentItem as TournamentItemCore } from 'core/components';
import { TournamentInfo } from 'components/tournament-game/tournament-info/tournament-info';

import { PromoTournamentTabs } from '../promo-tournament-tabs/promo-tournament-tabs';

const TournamentUI = ({
  item: {
    id,
    startDate,
    endDate,
    slugUrl,
    tournamentRankingList,
    tournamentAwards,
    tournamentGames,
    description: {
      backgroundBanner,
    },
  },
  status,
  className
}) => {
  const filteredTournamentRankingList = tournamentRankingList?.filter(t => t.ranking > 0);

  return (
    <div className={classNames('promo-tournament-card rounded overflow-hidden', className)}>
      <TournamentInfo
        tournamentData={{
          id,
          startDate,
          endDate
        }}
        tournamentStatus={status}
        backgroundPath={backgroundBanner}
        slugUrl={slugUrl}
        isPromoCard
      >
        <PromoTournamentTabs
          slugUrl={slugUrl}
          tournamentStatus={status}
          tournamentRankingList={filteredTournamentRankingList}
          tournamentAwards={tournamentAwards}
          tournamentGames={tournamentGames}
        />
      </TournamentInfo>
    </div>
  );
};

TournamentUI.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    description: PropTypes.shape({
      prize: PropTypes.string,
      background: PropTypes.string,
      backgroundBanner: PropTypes.string,
      image: PropTypes.string
    }),
    slugUrl: PropTypes.string.isRequired,
    tournamentRankingList: PropTypes.arrayOf(PropTypes.shape()),
    tournamentAwards: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    tournamentGames: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    startDate: PropTypes.number.isRequired,
    endDate: PropTypes.number.isRequired
  }).isRequired,
  status: PropTypes.string.isRequired,
  className: PropTypes.string,
};

TournamentUI.defaultProps = {
  className: null,
};

export const PromoTournament = withCoreComponent(TournamentItemCore, TournamentUI);
