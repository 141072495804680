import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withCoreComponent, withGameActions } from 'core/hocs';
import { AllGames as AllGamesCore } from 'core/components';
import { LoggedIn } from './logged-in/logged-in';
import { NotLoggedIn } from './not-logged-in/not-logged-in';

import './all-games.scss';

const AllGamesUI = ({
  games,
  openGame,
  toggleFavoriteGame,
  isUserLoggedIn,
  className
}) => {
  const AllGamesComponent = isUserLoggedIn ? LoggedIn : NotLoggedIn;

  return (
    <div className={classNames('all-games', className)}>
      <AllGamesComponent
        games={games}
        openGame={openGame}
        toggleFavoriteGame={toggleFavoriteGame}
      />
    </div>
  );
};

AllGamesUI.propTypes = {
  games: PropTypes.arrayOf(PropTypes.shape),
  openGame: PropTypes.func.isRequired,
  toggleFavoriteGame: PropTypes.func.isRequired,
  isUserLoggedIn: PropTypes.bool.isRequired,
  className: PropTypes.string
};

AllGamesUI.defaultProps = {
  games: [],
  className: ''
};

export const AllGames = withCoreComponent(AllGamesCore, withGameActions(AllGamesUI));

