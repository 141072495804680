import React, { Fragment } from 'react';
import { TOURNAMENT_STATUSES } from 'core/constants';
import PropTypes from 'prop-types';
import { withIsInsideReactNativeApp } from 'core/hocs';

import { AllGames } from 'components/all-games/all-games';
import { PromoPwaInstall } from 'components/promo-pwa-install/promo-pwa-install';
import { PromoTournaments } from 'components/promo-tournaments/promo-tournaments';
import { MostPopularGames } from 'components/most-popular-games/most-popular-games';
import { SkipServerRender } from 'components/skip-server-render/skip-server-render';
import { NavigationBanners } from 'components/navigation-banners/navigation-banners';
import { EnjoyExcellentService } from 'components/enjoy-excellent-service/enjoy-excellent-service';
import { ProducersPromo } from 'components/producers-promo/producers-promo';

const ALL_GAMES_MOBILE_GAMES_COUNT = 16;
const ALL_GAMES_DESKTOP_GAMES_COUNT = 35;

const TOP_PRODUCERS = [
  'Evolution',
  'Microgaming',
  'Netent',
  'Yggdrasil',
  'Quickspin'
];

const NotLoggedInUI = ({ isInsideReactNativeApp }) => (
  <Fragment>
    <NavigationBanners className="mb-5" />

    {/* <SportsQuickLinks className="mt-2_5 mt-lg-3_75 mb-5" /> */}

    <MostPopularGames coreProps={{ size: 200, isGamesExtendedFromCms: true }} />

    <SkipServerRender skip>
      <ProducersPromo
        coreProps={{ topProducers: TOP_PRODUCERS }}
      />
    </SkipServerRender>

    <div className="d-none d-md-block px-2 px-sm-2_5 px-lg-5 pt-0_25 mx-n2 mx-sm-n2_5 mx-lg-n5">
      <AllGames
        className="mt-5 mt-md-7 not-logged-in"
        coreProps={{ size: ALL_GAMES_DESKTOP_GAMES_COUNT }}
      />
    </div>

    <SkipServerRender skip>
      {!isInsideReactNativeApp && (
        <PromoPwaInstall />
      )}
    </SkipServerRender>

    <div className="mt-5 mt-md-7">
      <EnjoyExcellentService />
    </div>

    <div className="d-md-none px-2 px-sm-2_5 px-lg-5 pt-0_25 mx-n2 mx-sm-n2_5 mx-lg-n5">
      <AllGames
        className="mb-5 mt-5 mt-md-7 not-logged-in"
        coreProps={{ size: ALL_GAMES_MOBILE_GAMES_COUNT }}
      />
    </div>

    <PromoTournaments
      coreProps={{
        availableStatuses: [
          TOURNAMENT_STATUSES.ACTIVE,
          TOURNAMENT_STATUSES.PENDING,
          TOURNAMENT_STATUSES.AWARDED,
          TOURNAMENT_STATUSES.COMPLETED,
        ]
      }}
      isHomePage
      isCardType={false}
    />

  </Fragment>
);

NotLoggedInUI.propTypes = {
  isInsideReactNativeApp: PropTypes.bool.isRequired,
};

export const NotLoggedIn = withIsInsideReactNativeApp(NotLoggedInUI);
