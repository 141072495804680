import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import classNames from 'classnames';
import {
  TournamentsBannersList as TournamentsBannersListCore,
  FormattedTag,
} from 'core/components';
import { isEmptyOrNil } from 'core/helpers';
import { withCoreComponent, withUser } from 'core/hocs';
import { TOURNAMENT_STATUSES } from 'core/constants';
import { Carousel } from 'components/carousel/carousel';
import { PromoTournament } from './promo-tournament/promo-tournament';

import './promo-tournaments.scss';

const {
  ACTIVE,
  PENDING,
  AWARDED,
  COMPLETED,
} = TOURNAMENT_STATUSES;

const responsiveCarouselSettings = [
  {
    breakpoint: 719,
    settings: {
      arrows: false,
      slidesToShow: 1.02,
    },
  },
  {
    breakpoint: 1023,
    settings: {
      arrows: false,
      slidesToShow: 1,
    },
  },
  {
    breakpoint: 9999,
    settings: {
      dots: false,
      arrows: true,
      slidesToShow: 1,
    },
  },
];

export class PromoTournamentsUI extends Component {
  static propTypes = {
    isUserLoggedIn: PropTypes.bool.isRequired,
    items: PropTypes.shape(),
    isCardType: PropTypes.bool,
    isHomePage: PropTypes.bool,
    classNameTournament: PropTypes.string,
  };

  static defaultProps = {
    items: {},
    isCardType: true,
    isHomePage: false,
    classNameTournament: '',
  };

  createTournamentElementsList = () => {
    const {
      items,
      classNameTournament,
      isHomePage,
    } = this.props;

    const targetItems = {
      [ACTIVE]: items.ACTIVE,
      [PENDING]: items.PENDING,
      [AWARDED]: items.AWARDED,
      [COMPLETED]: items.COMPLETED,
    };

    const tournaments = R.compose(
      R.values,
      R.mapObjIndexed((id, status) => (
        <div key={`${status}_${id}`}>
          <PromoTournament
            coreProps={{ id }}
            status={status}
            className={classNames(classNameTournament, {
              'mb-3_5 mb-md-5': !isHomePage,
            })}
          />
        </div>
      )),
      R.reject(isEmptyOrNil),
    )(targetItems);

    return tournaments;
  };

  render() {
    const {
      items,
      isCardType,
      isHomePage,
      isUserLoggedIn,
    } = this.props;

    if (isEmptyOrNil(items)) {
      return null;
    }

    const tournamentsElementsList = this.createTournamentElementsList();
    const firstTournamentElement = R.propOr(null, 0, tournamentsElementsList);

    return (
      <>
        <div
          className={classNames('promo-tournaments', {
            'position-relative mt-5 mt-md-11 mb-md-6': isHomePage,
          })}
        >
          {isHomePage && isUserLoggedIn && (
            <FormattedTag id="home.tournaments.title" className="h3 d-block text-uppercase mb-1_25 font-weight-bold" isHtml />
          )}

          {isHomePage && !isUserLoggedIn && (
            <div
              className="promo-tournaments-home-title text-uppercase d-flex mb-3 mb-md-5 w-100"
            >
              <div className="d-flex align-items-center overflow-hidden title-wrap">
                <FormattedTag
                  id="home.tournaments.title.mobile.1"
                  className="d-block d-sm-none text-center w-100 overflow-hidden font-weight-bold tournaments-title"
                  isHtml
                />

                <FormattedTag
                  id="home.tournaments.title.desktop.1"
                  className="d-none d-sm-block font-weight-bold tournaments-title"
                  isHtml
                />
              </div>

              <FormattedTag
                id="home.tournaments.title.mobile.2"
                className="d-block d-sm-none text-primary text-center w-100 overflow-hidden font-weight-bold tournaments-title"
                isHtml
              />

              <FormattedTag
                id="home.tournaments.title.desktop.2"
                className="d-none d-sm-block text-primary ml-0_75 ml-md-1_5 font-weight-bold tournaments-title"
                isHtml
              />
            </div>
          )}

          {!isEmptyOrNil(tournamentsElementsList) && (
            isCardType ? (
              tournamentsElementsList
            ) : (
              <>
                {isUserLoggedIn ? (
                  <Carousel
                    settings={{
                      arrows: false,
                      dots: true,
                      slidesToShow: 1,
                      responsive: responsiveCarouselSettings,
                    }}
                    className="promo-tournaments-carousel"
                  >
                    {tournamentsElementsList.map(tournament => (
                      <div
                        key={tournament.key}
                        className="promo-tournaments-carousel-item"
                      >
                        {tournament}
                      </div>
                    ))}
                  </Carousel>
                ) : (
                  firstTournamentElement
                )}
              </>
            )
          )}
        </div>
      </>
    );
  }
}

export const PromoTournaments = withCoreComponent(
  TournamentsBannersListCore,
  withUser(
    PromoTournamentsUI,
  ),
);
