import React from 'react';
import { TOURNAMENT_STATUSES } from 'core/constants';

import { RecommendedGames } from 'components/recommended-games/recommended-games';
import { Producers } from 'components/producers/producers';
import { MostPopularGames } from 'components/most-popular-games/most-popular-games';
import { NewestGames } from 'components/newest-games/newest-games';
import { RecentlyPlayedGames } from 'components/recently-played-games/recently-played-games';
import { AllGames } from 'components/all-games/all-games';
import { PromoTournaments } from 'components/promo-tournaments/promo-tournaments';

const ALL_GAMES_DESKTOP_GAMES_COUNT = 35;

export const LoggedIn = () => (
  <>

    <RecentlyPlayedGames className="mb-2_5 mb-lg-3_5 recently" />

    <MostPopularGames coreProps={{ size: 200, isGamesExtendedFromCms: true }} className="mb-2_5 mb-lg-3_5" />

    {/* <GameTags className="mb-5 mt-5" /> */}

    <NewestGames coreProps={{ size: 100 }} className="mb-4 mb-lg-5" />

    <RecommendedGames coreProps={{ size: 200 }} className="mb-4 mb-lg-5" />

    <Producers className="my-2_5 my-lg-3_75" />

    <AllGames coreProps={{ size: ALL_GAMES_DESKTOP_GAMES_COUNT }} />

    <PromoTournaments
      coreProps={{
        availableStatuses: [
          TOURNAMENT_STATUSES.ACTIVE,
          TOURNAMENT_STATUSES.PENDING,
          TOURNAMENT_STATUSES.AWARDED,
          TOURNAMENT_STATUSES.COMPLETED,
        ]
      }}
      isHomePage
      isCardType={false}
    />

  </>
);
