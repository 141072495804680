import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { withUser } from 'core/hocs';
import { USER_FIELDS } from 'core/constants';

import { MainBanner } from 'components/main-banner/main-banner';

import { LoggedIn } from './logged-in/logged-in';
import { NotLoggedIn } from './not-logged-in/not-logged-in';

import './home.scss';

export const HomeUI = ({ isUserLoggedIn }) => (
  <>
    <MainBanner
      className={classNames('home-page-banner', {
        'logged-in': isUserLoggedIn,
        'not-logged-in': !isUserLoggedIn,
      })}
    />

    <div className={classNames('container-fluid main-content px-sm-2_5 px-lg-5', {
      'logged-in': isUserLoggedIn,
      'not-logged-in': !isUserLoggedIn,
    })}
    >
      {isUserLoggedIn ? <LoggedIn /> : <NotLoggedIn />}

    </div>
  </>
);

HomeUI.propTypes = {
  isUserLoggedIn: PropTypes.bool.isRequired,
};

export const Home = withUser(HomeUI, [USER_FIELDS.IS_USER_LOGGED_IN]);
