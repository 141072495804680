import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import {
  NewestGames as NewestGamesCore
} from 'core/components';
import { withCoreComponent } from 'core/hocs';
import { GAMES_CATEGORY_PAGE } from 'core/constants';
import { GameSectionHeader } from 'components/game-section-header/game-section-header';

import { GamesCarousel } from 'components/games-carousel/games-carousel';

import './newest-games.scss';

export const NewestGamesUI = ({
  games,
  isUserLoggedIn,
  className,
  isInProgress,
}) => (
  <div className={classNames('newest-games', className)}>
    <GameSectionHeader
      route={`/category/${GAMES_CATEGORY_PAGE.NEWEST}`}
      headerKey="games.newest"
    />
    <GamesCarousel
      isInProgress={isInProgress}
      isUserLoggedIn={isUserLoggedIn}
      withStubs={isUserLoggedIn}
      isArrowsToShow={false}
      games={games}
    />
  </div>
);

NewestGamesUI.propTypes = {
  isUserLoggedIn: PropTypes.bool.isRequired,
  isInProgress: PropTypes.bool.isRequired,
  games: PropTypes.arrayOf(PropTypes.shape({})),
  className: PropTypes.string,
};

NewestGamesUI.defaultProps = {
  games: [],
  className: null,
};

export const NewestGames = withCoreComponent(NewestGamesCore, NewestGamesUI);
