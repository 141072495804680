import React from 'react';
import PropTypes from 'prop-types';

export const IconGames = ({ className }) => (
  <svg className={className} width="139" height="140" viewBox="0 0 139 140" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#icon-games-filter)">
      <path
        opacity="0.5"
        d="M74.9499 86.4106V58.4238C74.9499 56.0726 73.0359 54.1641 70.6764 54.1641H69.3509C66.9914 54.1641 65.0774 56.0726 65.0774 58.4266V86.4106C65.0774 88.7618 66.9914 90.6703 69.3509 90.6703H70.6764C73.0359 90.6703 74.9499 88.7618 74.9499 86.4078V86.4106Z"
        fill="url(#icon-games-1)"
      />
      <path
        opacity="0.85"
        d="M70 66.6215C78.1703 66.6215 84.795 60.0187 84.795 51.8733C84.795 43.725 78.1675 37.125 70 37.125C61.8325 37.125 55.2078 43.7277 55.2078 51.8733C55.2078 60.0187 61.8298 66.6188 70.0028 66.6188L70 66.6215Z"
        fill="#00BEFF"
      />
      <path
        opacity="0.85"
        d="M87.875 72.875H81.9983C79.9275 72.875 78.25 74.723 78.25 77C78.25 79.277 79.9275 81.125 81.9983 81.125H87.875C89.9458 81.125 91.6233 79.277 91.6233 77C91.6233 74.723 89.9458 72.875 87.875 72.875Z"
        fill="url(#icon-games-2)"
      />
      <path
        opacity="0.85"
        d="M92.7265 77H46.2735C43.914 77 42 78.6417 42 80.6685V89.8315C42 91.8583 43.914 93.5 46.2735 93.5H92.7265C95.086 93.5 97 91.8583 97 89.8315V80.6685C97 78.6417 95.086 77 92.7265 77Z"
        fill="url(#icon-games-3)"
      />
      <path
        d="M76.2451 48.664C76.6423 48.6647 77.0358 48.5872 77.4031 48.4359C77.7704 48.2845 78.1043 48.0623 78.3857 47.7819C78.6671 47.5015 78.8905 47.1684 79.0432 46.8017C79.1959 46.435 79.2749 46.0418 79.2756 45.6445C79.2749 45.247 79.1958 44.8536 79.043 44.4867C78.8901 44.1198 78.6664 43.7866 78.3847 43.5061C78.103 43.2257 77.7688 43.0036 77.4012 42.8524C77.0336 42.7012 76.6398 42.6239 76.2423 42.625C75.4405 42.6243 74.6713 42.9419 74.1035 43.5081C73.5358 44.0743 73.2161 44.8427 73.2146 45.6445C73.2161 46.4463 73.5358 47.2147 74.1035 47.7809C74.6713 48.3471 75.4405 48.6647 76.2423 48.664H76.2451Z"
        fill="white"
      />
    </g>

  </svg>
);

IconGames.propTypes = {
  className: PropTypes.string,
};

IconGames.defaultProps = {
  className: '',
};
