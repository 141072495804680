import React from 'react';
import PropTypes from 'prop-types';

export const IconPayment = ({ className }) => (
  <svg className={className} width="144" height="136" viewBox="0 0 144 136" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#icon-payment-filter)">
      <path d="M42.7334 47.4554L84.3305 37.0561C84.8468 36.9174 85.3934 37.0409 85.8166 37.371C86.238 37.6994 86.4834 38.2038 86.4834 38.7369V49.1362C86.4834 50.0942 85.7082 50.8694 84.7502 50.8694H43.1532L42.7334 47.4554Z" fill="url(#icon-payment-1)" />
      <path d="M95.3806 64.7351C96.3386 64.7351 97.1138 63.9599 97.1138 63.0019V49.1362C97.1138 48.1782 96.3386 47.403 95.3806 47.403H43.1531C42.1951 47.403 41.4199 48.1782 41.4199 49.1362V87.2668C41.4199 88.2248 42.1951 89 43.1531 89H95.3806C96.3386 89 97.1138 88.2248 97.1138 87.2668V73.4011C97.1138 72.4431 96.3386 71.6679 95.3806 71.6679H83.017V64.7351H95.3806Z" fill="url(#icon-payment-2)" />
      <path d="M100.296 61.0001H82.7332C81.7752 61.0001 81 61.7753 81 62.7333V73.1326C81 74.0906 81.7752 74.8658 82.7332 74.8658H100.296C101.254 74.8658 102.03 74.0906 102.03 73.1326V62.7333C102.03 61.7753 101.254 61.0001 100.296 61.0001Z" fill="url(#icon-payment-3)" />
      <path d="M93.6473 69.9348C94.6045 69.9348 95.3805 69.1588 95.3805 68.2016C95.3805 67.2444 94.6045 66.4684 93.6473 66.4684C92.69 66.4684 91.9141 67.2444 91.9141 68.2016C91.9141 69.1588 92.69 69.9348 93.6473 69.9348Z" fill="url(#icon-payment-4)" />
    </g>
  </svg>
);

IconPayment.propTypes = {
  className: PropTypes.string
};

IconPayment.defaultProps = {
  className: ''
};
