import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Producers as ProducersCore, FormattedTag, RouteLink } from 'core/components';
import { withCoreComponent } from 'core/hocs';
import { isEmptyOrNil } from 'core/helpers';

import { Carousel } from 'components/carousel/carousel';
import { producerIconsBig, UnknownProducerIcon } from 'components/games-filters/producer-icons';

import './producers.scss';

const settings = {
  arrows: false,
  slidesToShow: 5,
  slidesToScroll: 5,
  responsive: [
    {
      breakpoint: 719,
      settings: {
        slidesToShow: 2.22,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 1023,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
      },
    },
    {
      breakpoint: 9999,
      settings: {
        arrows: true,
      },
    },
  ],
};

export const ProducersUI = ({ items, className }) => (
  <div className={classNames('producers mx-lg-n3 px-lg-3', className)}>
    <div className="d-flex align-items-center mb-1_75 mb-md-2_5">
      <FormattedTag id="producers" className="font-weight-bold letter-spacing-default text-uppercase producers-title" />
    </div>

    <Carousel
      settings={settings}
      controlsPosition="side"
    >
      {!isEmptyOrNil(items) && items.map(({ slug, name }) => {
        const ProducerIcon = producerIconsBig[slug] || UnknownProducerIcon;
        const isGoldenHeroGroup = slug === 'golden-hero-group';

        return (
          <RouteLink key={slug} to={`/provider/${slug}`} className="producers-item position-relative overflow-hidden rounded d-flex align-items-center justify-content-center" draggable="false">
            <div className="producers-content position-absolute text-center">
              <div className={classNames('producers-content-icon-container d-flex align-items-center justify-content-center', {
                'small-sm-container': isGoldenHeroGroup
              })}
              >
                {ProducerIcon && (
                  <ProducerIcon className={`producers-${slug}`} />
                )}
              </div>
              <div className={classNames('producers-item-text font-weight-semi-bold letter-spacing-default text-blue-dark', {
                'mb-2 mt-1': !isGoldenHeroGroup,
                'mt-0_5': isGoldenHeroGroup,
              })}
              >
                {name}
              </div>
            </div>
          </RouteLink>
        );
      })}
    </Carousel>

  </div>
);

ProducersUI.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape()),
  className: PropTypes.string,
};

ProducersUI.defaultProps = {
  items: null,
  className: null,
};

export const Producers = withCoreComponent(ProducersCore, ProducersUI);
