import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withLocale } from 'core/hocs';
import { isEmptyOrNil, createListOfTournamentAwards } from 'core/helpers';
import { FormattedTag, RouteLink } from 'core/components';
import { TOURNAMENT_STATUSES } from 'core/constants';

import { getUsernameFromEmail, setUsernameHidden } from '../../../helpers/username';
import { formatFloatAmountWithLocale } from '../../../helpers/numbers';
import { TOURNAMENTS_EMPTY_FIELDS } from '../../../constants';

import './promo-tournament-table.scss';

const MAX_RANKING_ROWS = 3;

// const createAwardsString = award => award.length > 1 ? R.join(' + ', award) : award[0];

export const PromoTournamentTableUI = ({
  rankings,
  tournamentAwards,
  status,
  locale,
  slugUrl,
}) => {
  const awards = createListOfTournamentAwards({ tournamentAwards });
  const isEmptyTable = status === TOURNAMENT_STATUSES.PENDING || isEmptyOrNil(rankings);

  if (isEmptyOrNil(awards)) {
    return null;
  }

  return (
    <div className="promo-tournament-table-wrapper">
      <div className="promo-tournament-table rounded px-1_25 mb-1_25">
        {status !== TOURNAMENT_STATUSES.PENDING
        && !isEmptyOrNil(awards)
        && !isEmptyOrNil(rankings)
        && R.take(MAX_RANKING_ROWS, awards)
          .map((award, idx) => (
            <div
              // eslint-disable-next-line react/no-array-index-key
              key={`${idx}_${award[0]}`}
              className="promo-tournament-table-item d-flex align-items-center"
            >
              <div className="promo-tournament-table-item-place flex-shrink-0 text-center mr-2_75">
                <div
                  className={classNames('promo-tournament-table-item-place-text d-flex justify-content-center align-items-center font-weight-semi-bold text-blue-dark', `place-${R.inc(idx)}`)}
                >
                  <span>
                    {R.inc(idx)}
                  </span>
                </div>
              </div>

              <div className="promo-tournament-table-item-text text-truncate">
                <div className="font-weight-bold text-blue-dark text-truncate mb-0_5">
                  {rankings[idx]
                    ? setUsernameHidden(getUsernameFromEmail(rankings[idx].userid))
                    : TOURNAMENTS_EMPTY_FIELDS.USER}
                </div>

                <div className="font-weight-medium text-blue-dark-70 text-truncate">
                  {rankings[idx]
                    ? formatFloatAmountWithLocale(rankings[idx].points, locale)
                    : TOURNAMENTS_EMPTY_FIELDS.POINTS}
                </div>
              </div>
            </div>
          ))}

        {isEmptyTable
        && !isEmptyOrNil(awards)
        && R.take(MAX_RANKING_ROWS, awards)
          .map((award, idx) => {
            const awardData = R.split('+', award[0]);

            return (
              <div
                // eslint-disable-next-line react/no-array-index-key
                key={`${idx}_${award[0]}`}
                className="promo-tournament-table-item d-flex align-items-center"
              >
                <div className="promo-tournament-table-item-place flex-shrink-0 text-center mr-2_75">
                  <div
                    className={classNames('promo-tournament-table-item-place-text d-flex justify-content-center align-items-center font-weight-semi-bold text-blue-dark', `place-${R.inc(idx)}`)}
                  >
                    <span>
                      {R.inc(idx)}
                    </span>
                  </div>
                </div>

                <div className="promo-tournament-table-item-text text-truncate">
                  <div className="font-weight-bold text-blue-dark text-truncate mb-0_5">
                    {R.when(isEmptyOrNil, TOURNAMENTS_EMPTY_FIELDS.PRIZE)(awardData)}
                  </div>

                  <FormattedTag
                    tag="div"
                    id={`promo.tournament.table.place-desc.${R.inc(idx)}`}
                    className="font-weight-medium text-blue-dark-70 text-truncate"
                    isHtml
                  />
                </div>
              </div>
            );
          })}
      </div>

      <div className="d-flex justify-content-center w-100">
        <FormattedTag
          tag={RouteLink}
          id="see-all"
          to={`/tournament/${slugUrl}`}
          className="promo-tournament-table-link"
        />
      </div>
    </div>
  );
};

PromoTournamentTableUI.propTypes = {
  rankings: PropTypes.arrayOf(PropTypes.shape()),
  tournamentAwards: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  status: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  slugUrl: PropTypes.string.isRequired,
};

PromoTournamentTableUI.defaultProps = {
  rankings: null,
};

export const PromoTournamentTable = withLocale(PromoTournamentTableUI);
