import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from 'reactstrap/lib/Button';
import { FormattedMessageWithUser, FormattedTag, RouteLink } from 'core/components';
import { getIntlKeyByEnv, getIsHtmlContentForbidden } from 'core/helpers';
import { SkipServerRender } from 'components/skip-server-render/skip-server-render';
import { TOURNAMENT_STATUSES } from 'core/constants';
import Countdown from 'react-countdown-now';
import { createBasicPath } from '../../../helpers/check-pixel-ratio';
import { withPixelRatio } from '../../../hocs/with-pixel-ratio';

import './tournament-info.scss';

const {
  ACTIVE,
  PENDING,
  AWARDED,
  COMPLETED,
} = TOURNAMENT_STATUSES;

const TOURNAMENT_COUNTDOWN_CLASSES = 'tournament-countdown-item font-weight-bold text-black d-flex justify-content-center align-items-center overflow-hidden bg-white mr-1';
const TOURNAMENT_BACKGROUND_CLASSES = 'tournament-background-page position-relative flex-column align-items-center overflow-hidden pb-2_5 pt-14 px-sm-4 px-md-6 pt-md-17';

const CountdownRenderer = ({
  days,
  hours,
  minutes,
  seconds,
}) => (
  <div className="tournament-countdown d-flex align-items-center position-relative">
    <div className={classNames(TOURNAMENT_COUNTDOWN_CLASSES)}>
      {days}<FormattedTag id="countdown.day" isHtml />
    </div>

    <div className={classNames(TOURNAMENT_COUNTDOWN_CLASSES)}>
      {hours}<FormattedTag id="countdown.hour" isHtml />
    </div>

    <span className="tournament-countdown-item-divider position-relative mr-1" />

    <div className={classNames(TOURNAMENT_COUNTDOWN_CLASSES)}>
      {minutes}<FormattedTag id="countdown.minute" isHtml />
    </div>

    <span className="tournament-countdown-item-divider position-relative mr-1" />

    <div className={classNames(TOURNAMENT_COUNTDOWN_CLASSES)}>
      {seconds}<FormattedTag id="countdown.second" isHtml />
    </div>
  </div>
);

CountdownRenderer.propTypes = {
  days: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  hours: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  minutes: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  seconds: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

const TournamentInfoUI = ({
  tournamentData,
  className,
  backgroundPath,
  tournamentStatus,
  pixelRatio,
  isPromoCard,
  slugUrl,
  children: tabsData,
}) => {
  const {
    startDate,
    endDate,
    id,
  } = tournamentData;

  const basicBackgroundPath = createBasicPath(backgroundPath);

  const timeTextIntlKeyParts = {
    [ACTIVE]: 'ends-in',
    [PENDING]: 'starts-in',
    [AWARDED]: 'finished',
    [COMPLETED]: 'finished',
  };

  const btnTextIntlKeyParts = {
    [ACTIVE]: 'play-now',
    [PENDING]: 'see-details',
    [AWARDED]: 'see-results',
    [COMPLETED]: 'see-results',
  };

  const withTimeData = [ACTIVE, PENDING].includes(tournamentStatus);
  const isLive = tournamentStatus === ACTIVE;

  const bannerData = (
    <div
      className={classNames('tournament-banner-data position-relative d-flex flex-column', {
        'align-items-center px-1_25': !isPromoCard,
        'flex-sm-row justify-content-sm-between w-100 pt-2_5 px-2 pb-3 pt-md-4_5 px-md-5 pb-md-2_75 mx-auto mx-sm-0': isPromoCard,
        'tournament-banner-data-with-timer': withTimeData,
      })}
    >
      <div
        className={classNames('d-flex flex-column', {
          'justify-content-sm-between': isPromoCard,
        })}
      >
        <div className="d-flex flex-column text-white">
          <FormattedTag
            id={getIntlKeyByEnv(`tournament.title.${id}`)}
            tag="div"
            className={classNames('tournament-title', {
              'd-none d-md-block text-uppercase text-center mb-1 font-weight-bold': !isPromoCard,
              'text-capitalize mb-0_75': isPromoCard,
            })}
            isHtml
          />

          <FormattedTag
            id={getIntlKeyByEnv(`tournament.prize.${id}`)}
            tag="div"
            className={classNames('tournament-prize text-uppercase', {
              'text-center mb-3 mb-md-5': !isPromoCard,
              'mb-4 mb-md-5_5': isPromoCard,
            })}
            isHtml
          />
        </div>

        <div
          className={classNames('tournament-time-data d-flex flex-column align-items-center', {
            'flex-sm-row justify-content-sm-center align-items-sm-end': !isPromoCard,
            'tournament-time-data-with-time flex-lg-row align-items-sm-start align-items-lg-end': isPromoCard && withTimeData,
            'flex-sm-row': isPromoCard && !withTimeData,
          })}
        >
          <div className="d-flex flex-column">
            <div
              className={classNames('tournament-status d-flex align-items-center w-100', {
                'mb-1': !isPromoCard || (isPromoCard && withTimeData),
                'mb-0': isPromoCard && !withTimeData,
              })}
            >
              {isLive && (
                <FormattedTag
                  id="tournaments.tournament-info.live"
                  className="tournament-live-indicator d-inline-block font-weight-bold text-capitalize text-white position-relative pl-1_5 mr-1"
                  isHtml
                />
              )}

              <FormattedTag
                id={`tournaments.tournament-info.${timeTextIntlKeyParts[tournamentStatus]}`}
                className={classNames('tournament-status-text text-capitalize', {
                  'font-weight-semi-bold text-white-70': isLive,
                  'text-white font-weight-bold': !isLive,
                  'tournament-status-text-no-timer text-first-letter-uppercase text-center position-relative px-5_25': !withTimeData,
                })}
                isHtml
              />
            </div>

            {withTimeData && (
              <SkipServerRender skip>
                <Countdown
                  date={isLive ? endDate : startDate}
                  renderer={CountdownRenderer}
                />
              </SkipServerRender>
            )}
          </div>

          {((!isPromoCard && isLive) || isPromoCard) && (
            <Button
              tag={isPromoCard && slugUrl ? RouteLink : 'a'}
              href="#tournaments-slots-mobile"
              to={isPromoCard && slugUrl ? `/tournament/${slugUrl}` : ''}
              color="primary"
              className="tournament-button-link d-sm-none flex-shrink-0 mt-2_5"
            >
              <FormattedTag id={btnTextIntlKeyParts[tournamentStatus]} isHtml />
            </Button>
          )}

          {((!isPromoCard && isLive) || isPromoCard) && (
            <Button
              tag={isPromoCard && slugUrl ? RouteLink : 'a'}
              href="#tournaments-slots-tablet"
              to={isPromoCard && slugUrl ? `/tournament/${slugUrl}` : ''}
              color="primary"
              className={classNames('tournament-button-link d-none d-sm-block d-md-none flex-shrink-0 mt-2_5', {
                'ml-sm-1_5': !isPromoCard,
                'mt-sm-0 ml-sm-3': isPromoCard && !withTimeData,
                'ml-lg-2_5': isPromoCard && withTimeData,
              })}
            >
              <FormattedTag id={btnTextIntlKeyParts[tournamentStatus]} isHtml />
            </Button>
          )}

          {((!isPromoCard && isLive) || isPromoCard) && (
            <Button
              tag={isPromoCard && slugUrl ? RouteLink : 'a'}
              href="#tournaments-slots-desktop"
              to={isPromoCard && slugUrl ? `/tournament/${slugUrl}` : ''}
              color="primary"
              className={classNames('tournament-button-link d-none d-md-block flex-shrink-0 mt-2_5', {
                'ml-sm-1_5': !isPromoCard,
                'mt-sm-0 ml-sm-3': isPromoCard && !withTimeData,
                'ml-lg-2_5': isPromoCard && withTimeData,
              })}
            >
              <FormattedTag id={btnTextIntlKeyParts[tournamentStatus]} isHtml />
            </Button>
          )}
        </div>
      </div>

      {isPromoCard && tabsData}
    </div>
  );

  return (
    <div
      className={classNames('tournament-info overflow-hidden', className, {
        'is-promo-card': isPromoCard,
      })}
    >
      {/* BACKGROUNDS */}
      <>
        {/* MOBILE */}
        <div
          className={classNames('tournament-background d-flex d-sm-none', {
            [TOURNAMENT_BACKGROUND_CLASSES]: !isPromoCard,
            'tournament-background-card': isPromoCard,
          })}
          style={basicBackgroundPath && {
            backgroundImage: `url("${basicBackgroundPath[0]}-m${pixelRatio}${basicBackgroundPath[1]}", url("${basicBackgroundPath[0]}-m${basicBackgroundPath[1]}")`,
          }}
        >
          {bannerData}
        </div>

        {/* DESKTOP */}
        <div
          className={classNames('tournament-background d-none d-sm-flex', {
            [TOURNAMENT_BACKGROUND_CLASSES]: !isPromoCard,
            'tournament-background-card': isPromoCard,
          })}
          style={basicBackgroundPath && {
            backgroundImage: `url("${basicBackgroundPath[0]}${pixelRatio}${basicBackgroundPath[1]}"), url("${backgroundPath}")`,
          }}
        >
          {bannerData}
        </div>
      </>

      {!isPromoCard && (
        <div className="tournament-desc position-relative px-2 px-md-5 mt-2 mb-1 mt-sm-4 mb-sm-2">
          <div className="overflow-hidden">
            <FormattedMessageWithUser id={getIntlKeyByEnv(`tournament.terms.description.short.${id}`)}>
              {(txt) => {
                const key = getIntlKeyByEnv(`tournament.terms.description.short.${id}`);
                const isContentForbidden = getIsHtmlContentForbidden({
                  key,
                  htmlTxt: txt
                });

                return (
                  <div dangerouslySetInnerHTML={{ __html: !isContentForbidden ? txt : key }} />
                );
              }}
            </FormattedMessageWithUser>

            <FormattedMessageWithUser id={getIntlKeyByEnv(`tournament.terms.description.long.${id}`)}>
              {(txt) => {
                const key = getIntlKeyByEnv(`tournament.terms.description.long.${id}`);
                const isContentForbidden = getIsHtmlContentForbidden({
                  key,
                  htmlTxt: txt
                });

                return (
                  <div dangerouslySetInnerHTML={{ __html: !isContentForbidden ? txt : key }} />
                );
              }}
            </FormattedMessageWithUser>
          </div>
        </div>
      )}
    </div>
  );
};

TournamentInfoUI.propTypes = {
  className: PropTypes.string,
  tournamentData: PropTypes.shape({
    id: PropTypes.number.isRequired,
    startDate: PropTypes.number,
    endDate: PropTypes.number,
  }).isRequired,
  backgroundPath: PropTypes.string,
  tournamentStatus: PropTypes.string.isRequired,
  pixelRatio: PropTypes.string.isRequired,
  isPromoCard: PropTypes.bool,
  slugUrl: PropTypes.string,
  children: PropTypes.node,
};

TournamentInfoUI.defaultProps = {
  className: '',
  backgroundPath: null,
  isPromoCard: false,
  slugUrl: '',
  children: null,
};

export const TournamentInfo = withPixelRatio(TournamentInfoUI);
