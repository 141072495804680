import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
  RouteLink,
  FormattedTag,
  Producers as ProducersCore,
} from 'core/components';
import { isEmptyOrNil } from 'core/helpers';
import { withCoreComponent } from 'core/hocs';

import { producerIconsBig } from 'components/games-filters/producer-icons';
import { MoreItemsBtn } from 'components/more-items-btn/more-items-btn';

import './producers-promo.scss';

const ELEMENTS_SHOWN_MAX = 5;

const ProducersPromoUI = ({ items }) => (
  <div className="producers-promo-wrapper mb-4 mb-md-8 mt-md-3">
    <div className="producers-promo-title-wrap text-center text-md-left text-mb-left mb-2_5 mb-md-5 d-flex align-items-center flex-sm-wrap">
      <FormattedTag
        isHtml
        id="games.title.by-top-game"
        className="font-weight-bold letter-spacing-default text-uppercase games-title mr-1"
      />
      <FormattedTag
        isHtml
        id="producers"
        className="font-weight-bold letter-spacing-default text-uppercase games-title text-primary d-sm-block"
        tag="div"
      />
    </div>

    <div className="producers-promo-items">
      {!isEmptyOrNil(items) && items.slice(0, ELEMENTS_SHOWN_MAX).map(({ slug }, idx) => {
        const ProducerIcon = producerIconsBig[slug];

        return (
          <RouteLink
            key={slug}
            to={`/provider/${slug}`}
            className={classNames('bg-white justify-content-center align-items-center method-icon', {
              'd-flex': idx < 5,
              'd-md-none': idx === 4,
            })}
          >

            {ProducerIcon && <ProducerIcon />}

          </RouteLink>
        );
      })}

      <span className="d-flex justify-content-center align-items-center method-icon bg-white">
        <MoreItemsBtn producerItemsAll={items.length} isProducers />
      </span>
    </div>
  </div>
);

ProducersPromoUI.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export const ProducersPromo = withCoreComponent(ProducersCore, ProducersPromoUI);

