import React from 'react';
import Button from 'reactstrap/lib/Button';
import PropTypes from 'prop-types';
import { FormattedTag, RouteLink } from 'core/components';
import { ELEMENTS_SHOWN_AMOUNT, PAYMENT_METHODS_ALL_AMOUNT } from '../../constants';

import './more-items-btn.scss';

const {
  elementsShownXs,
  elementsShownXsLarge,
  elementsShownSm,
  elementsShownMd,
  elementsShownLg,
} = ELEMENTS_SHOWN_AMOUNT;

const PAYMENTS_INFO = {
  link: '/payments',
  msgId: 'payments.promo.methods.more',
  hiddenElementsXs: PAYMENT_METHODS_ALL_AMOUNT - elementsShownXs,
  hiddenElementsXsLarge: PAYMENT_METHODS_ALL_AMOUNT - elementsShownXsLarge,
  hiddenElementsSm: PAYMENT_METHODS_ALL_AMOUNT - elementsShownSm,
  hiddenElementsMd: PAYMENT_METHODS_ALL_AMOUNT - elementsShownMd,
  hiddenElementsLg: PAYMENT_METHODS_ALL_AMOUNT - elementsShownLg,
};

const getProducersInfo = producerAllAmount => ({
  link: '/games',
  msgId: 'producers.promo.more',
  hiddenElementsXs: producerAllAmount - elementsShownXs,
  hiddenElementsXsLarge: producerAllAmount - elementsShownXsLarge,
  hiddenElementsSm: producerAllAmount - elementsShownSm,
  hiddenElementsMd: producerAllAmount - elementsShownMd,
  hiddenElementsLg: producerAllAmount - elementsShownLg,
});

export const MoreItemsBtn = ({ isProducers, isPayments, producerItemsAll }) => {
  const {
    link,
    msgId,
    // hiddenElementsXs,
    hiddenElementsXsLarge,
    hiddenElementsSm,
    hiddenElementsMd,
    hiddenElementsLg,
  } = (isProducers && !isPayments) ? getProducersInfo(producerItemsAll) : PAYMENTS_INFO;

  return (
    <Button tag={RouteLink} to={link} color="link-secondary" className="more-btn">
      {/* <FormattedTag values={{ value: hiddenElementsXs }} id={msgId} className="more-btn-xs" /> */}
      <FormattedTag values={{ value: hiddenElementsXsLarge }} id={msgId} className="more-btn-xs-large d-sm-none" />
      <FormattedTag values={{ value: hiddenElementsSm }} id={msgId} className="d-none d-sm-block d-md-none" />
      <FormattedTag values={{ value: hiddenElementsMd }} id={msgId} className="d-none d-md-block d-lg-none" />
      <FormattedTag values={{ value: hiddenElementsLg }} id={msgId} className="d-none d-lg-block" />
    </Button>
  );
};

MoreItemsBtn.propTypes = {
  isProducers: PropTypes.bool,
  isPayments: PropTypes.bool,
  producerItemsAll: PropTypes.number,
};

MoreItemsBtn.defaultProps = {
  isProducers: false,
  isPayments: false,
  producerItemsAll: 0,
};
