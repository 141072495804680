import React from 'react';
import PropTypes from 'prop-types';

import {
  FormattedTag,
  RecentlyPlayedGames as RecentlyPlayedGamesCore,
} from 'core/components';
import { isEmptyOrNil } from 'core/helpers';
import { withCoreComponent } from 'core/hocs';
import { GamesCarousel } from 'components/games-carousel/games-carousel';

import './recently-played-games.scss';

export const RecentlyPlayedGamesUI = ({
  games,
  isUserLoggedIn,
  className,
  isInProgress,
  isGamePage
}) => isUserLoggedIn && !isEmptyOrNil(games)
  && (
    <div className={className}>
      <div className="d-flex justify-content-between align-items-center mb-1_75">
        <FormattedTag
          id="games.recently-played"
          className="font-weight-bold letter-spacing-default text-uppercase games-title"
        />
      </div>

      <GamesCarousel
        isInProgress={isInProgress}
        isUserLoggedIn={isUserLoggedIn}
        isGamePage={isGamePage}
        games={games}
        withStubs
      />
    </div>
  );

RecentlyPlayedGamesUI.propTypes = {
  isUserLoggedIn: PropTypes.bool.isRequired,
  isInProgress: PropTypes.bool.isRequired,
  games: PropTypes.arrayOf(PropTypes.shape({})),
  isGamePage: PropTypes.bool,
  className: PropTypes.string,
};

RecentlyPlayedGamesUI.defaultProps = {
  games: [],
  className: null,
  isGamePage: false
};

export const RecentlyPlayedGames = withCoreComponent(RecentlyPlayedGamesCore, RecentlyPlayedGamesUI);
