import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withCoreComponent, withGameActions } from 'core/hocs';
import { isEmptyOrNil, getStaticUrl } from 'core/helpers';
import { TournamentGame as TournamentGameCore, FormattedTag, RouteLink } from 'core/components';
import { ImageLazyLoad, LAZY_LOAD_TYPES } from 'components/image-lazy-load/image-lazy-load';

const DEFAULT_THUMB_PATH = `${getStaticUrl()}/images/default-game-bg/thumb.jpg`;

class PromoTournamentGamesUI extends Component {
  static propTypes = {
    className: PropTypes.string,
    slugUrl: PropTypes.string.isRequired,
    games: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    openGame: PropTypes.func.isRequired,
  };

  static defaultProps = {
    className: '',
  };

  openGame = (e) => {
    const {
      dataset: {
        id: gameId,
        producer,
      },
    } = e.currentTarget;

    const { openGame } = this.props;

    openGame({ gameId, producer });
  };

  render() {
    const {
      className,
      slugUrl,
      games,
    } = this.props;

    const targetGames = (games || []).slice(0, 3);

    if (isEmptyOrNil(targetGames)) {
      return null;
    }

    return (
      <div className={classNames('promo-tournament-games-wrapper', className)}>
        <div className="promo-tournament-games rounded px-1_25 mb-1_25">
          {targetGames.map(({
            gameId,
            producer,
            thumbPath,
            title,
          }) => (
            <div
              key={`${gameId}-${producer}`}
              className="promo-tournament-games-item d-flex align-items-center"
              role="button"
              tabIndex="0"
              data-id={gameId}
              data-producer={producer}
              onClick={this.openGame}
              onKeyPress={this.openGame}
            >

              <ImageLazyLoad
                src="/assets/public/images/transparent-pixel.png"
                className="promo-tournament-games-item-image flex-shrink-0 rounded mr-2_5"
                classNameLoaded="promo-tournament-games-item-image-loaded"
                classNameError="promo-tournament-games-item-image-error"
                srcLazy={thumbPath}
                type={LAZY_LOAD_TYPES.BACKGROUND}
                srcFallback={DEFAULT_THUMB_PATH}
              />

              <div className="promo-tournament-games-item-text d-flex flex-column text-capitalize text-truncate">
                <span
                  className="font-weight-bold text-blue-dark text-truncate mb-0_5"
                  role="button"
                  tabIndex="0"
                  title={title}
                >
                  {title}
                </span>

                <span
                  className="font-weight-medium text-blue-dark-70 text-truncate"
                  role="button"
                  tabIndex="0"
                  title={producer}
                >
                  {producer}
                </span>
              </div>
            </div>
          ))}
        </div>

        <div className="d-flex justify-content-center w-100">
          <FormattedTag
            tag={RouteLink}
            id="see-all"
            to={`/tournament/${slugUrl}#tournaments-slots`}
            className="promo-tournament-games-link"
          />
        </div>
      </div>
    );
  }
}

export const PromoTournamentGames = withCoreComponent(
  TournamentGameCore,
  withGameActions(
    PromoTournamentGamesUI
  )
);
