import React from 'react';
import PropTypes from 'prop-types';
import Button from 'reactstrap/lib/Button';

import { isEmptyOrNil } from 'core/helpers';
import { FormattedTag, RouteLink } from 'core/components';

import { GameCard } from 'components/game-card/game-card';

import '../all-games.scss';

export const NotLoggedIn = ({
  games,
  openGame,
  toggleFavoriteGame,
}) => (
  <div className="all-games-wrapper">
    <div className="text-center text-md-left text-mb-left mb-2_5 mb-md-5">
      <FormattedTag
        id="games.category.all"
        className="font-weight-bold letter-spacing-default text-uppercase games-title"
        isHtml
      />
    </div>

    <div className="all-games-wrapper-list">
      {!isEmptyOrNil(games) && games.map((game, idx) => (
        <GameCard
        // eslint-disable-next-line react/no-array-index-key
          key={`${game.gameId}-${game.producer}-${idx}`}
          {...game}
          toggleFavoriteGame={toggleFavoriteGame}
          openGame={openGame}
        />
      ))}
    </div>

    <Button
      tag={RouteLink}
      color="primary"
      className="btn-show-more mt-3 mt-md-4_75 mb-md-8 d-table mx-auto px-0"
      to="/games"
    >
      <FormattedTag id="games.show-more-games" isHtml />
    </Button>
  </div>
);

NotLoggedIn.propTypes = {
  games: PropTypes.arrayOf(PropTypes.shape),
  openGame: PropTypes.func.isRequired,
  toggleFavoriteGame: PropTypes.func.isRequired,
};

NotLoggedIn.defaultProps = {
  games: [],
};

